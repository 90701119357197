import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(private angularFireMessaging: AngularFireMessaging, private _auth: AuthService, private _utility: UtilityService) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('./firebase-messaging-sw.js',  {scope: "firebase-cloud-messaging-push-scope"})
      .then(function(registration) {
        
        angularFireMessaging.useServiceWorker(registration);
      }).catch(function(err) {
        
      });
    }
  }

  requestPermission() {
    
    if (firebase.messaging.isSupported()) {
      this.angularFireMessaging.requestPermission
      .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
      .subscribe({
        next: (token) => {
      this._auth.updateServerDeviceToken({device_token: token, device_type: 'web'}).pipe(take(1)).subscribe((success) =>{
        this.listen()
      }) },
        error: (error) => { 
          // console.error(error);
          this._utility.routingAccordingToError(error);
        },  
      });
    }
  }

  listen() {

    if (firebase.messaging.isSupported()) {
      this.angularFireMessaging.messages
      .subscribe((message:any) => { 
        let redirection = message.data.urlToRedirect;
        var notificationData = (message.data);
        var notificationOptions = {
          body: notificationData.body,
          click_action: redirection,
          data: {
            click_action: redirection
          } //seems necessary for firefox foreground clicks
        };
        try {
          var notification = new Notification("Title", notificationOptions);
          notification.onclick = function (event) {
            event.preventDefault(); //prevent the browser from focusing the Notification's tab
            window.open(redirection, '_self');
            notification.close();
          }
        } catch (err) {
          try { //Need this part as on Android we can only display notifications thru the serviceworker
            navigator.serviceWorker.ready.then(function (registration) {
              registration.showNotification("Title", notificationOptions);
            });
          } catch (err1) {
            // console.log(err1.message);
          }
        }
      });
    }
  }
}
