import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectLayoutComponent } from './layouts/project-layout/project-layout.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FooterComponent } from './components/footer/footer.component';
import { CompanyHeaderComponent } from './components/company-header/company-header.component';
import { HttpClientModule } from '@angular/common/http';
import { CompanyFooterComponent } from './components/company-footer/company-footer.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxBootstrapModule } from '../shared/ngx-bootstrap/ngx-bootstrap.module';
import { DateAgoPipe } from '../shared/pipes/date-ago.pipe';
import { GooogleAnalyticsService } from './services/gooogle-analytics.service';
import { MoengageEventService } from './services/moengage.service';
import { MarketingLayoutComponent } from './layouts/marketing-layout/marketing-layout.component';
import { MarketingHeaderComponent } from './components/marketing-header/marketing-header.component';
import { MarketingFooterComponent } from './components/marketing-footer/marketing-footer.component';
import { ContactNumberComponent } from './components/contact-number/contact-number.component';
import { RequirementFormComponent } from './components/requirement-form/requirement-form.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SharedModule } from '../shared/shared.module';
import { PrimeNgModule } from '../shared/primeng/primeng.module';



@NgModule({
  declarations: [
    ProjectLayoutComponent,
    NotfoundComponent,
    HeaderComponent,
    FooterComponent,
    CompanyHeaderComponent,
    CompanyFooterComponent,
    PublicLayoutComponent,
    DateAgoPipe,
    MarketingLayoutComponent,
    MarketingHeaderComponent,
    MarketingFooterComponent,
    ContactNumberComponent,
    RequirementFormComponent,
  ],
  providers: [
    GooogleAnalyticsService,
    MoengageEventService
  ],
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxBootstrapModule,
    NgxIntlTelInputModule,
    PrimeNgModule
  ],
  exports: [
    HttpClientModule,
    CompanyHeaderComponent,
    DateAgoPipe,
    HeaderComponent,
    FooterComponent,
    CompanyFooterComponent,
    ContactNumberComponent,
    RequirementFormComponent
  ],
})
export class CoreModule { }
