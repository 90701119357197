import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { timeout, catchError } from "rxjs/operators";
import { SocialAuthService, GoogleLoginProvider } from "angularx-social-login";
import { StoreService } from './store.service';

@Injectable({
  providedIn: "root",
})
export class AuthService {

  baseUrl: string;
  loggedIn = new BehaviorSubject<boolean>(false);
  isAnyUserLoggedIn = new BehaviorSubject<boolean>(false);
  authenticated: boolean = false;

  constructor(
    private _http: HttpClient,
    private _baseService: BaseService,
    private _router: Router,
    private _socialService: SocialAuthService,
    private _storeService:StoreService
  ) {
    this.baseUrl = this._baseService.baseUrl;
  }

  checkIfAnyUserLoggedIn(){
    if(localStorage.getItem('company_token') || localStorage.getItem('client_token')){
      this.isAnyUserLoggedIn.next(true);
    }
  }
  
  userSignUp(data) {
    return this._http
      .post(this.baseUrl + "signup", data, { observe: "response" })
      .pipe(
        timeout(75000),
        catchError((error) => {
          throw error;
        })
      );
  }

  userLogin(data) {
    return this._http
      .post(this.baseUrl + "login", data, { observe: "response" })
      .pipe(
        timeout(75000),
        catchError((error) => {
          throw error;
        })
      );
  }
  // social login
  socialLogin(data) {
    return this._http
      .post(this.baseUrl + "socialLogin", data, { observe: "response" })
      .pipe(
        timeout(75000),
        catchError((error) => {
          throw error;
        })
      );
  }
  // verify user for signup
  verifyUser(data) {
    return this._http.get(this.baseUrl + "verifyUser/" + data).pipe(
      timeout(75000),
      catchError((error) => {
        throw error;
      })
    );
  }

  forgotPassword(data) {
    return this._http.post(this.baseUrl + "forgotPassword", data).pipe(
      timeout(75000),
      catchError((error) => {
        throw error;
      })
    );
  }

  verifyForPassword(token){
    return this._http.get(this.baseUrl+"recoverMyPassword/"+token).pipe(
      timeout(75000),
      catchError((error) => {
        throw error;
      })
    );
  }
  
  recoverPassword(data, token) {
    return this._http
      .post(this.baseUrl + "recoverMyPassword/" + token, data)
      .pipe(
        timeout(75000),
        catchError((error) => {
          throw error;
        })
      );
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  verifyUserLogin() {
    return localStorage.getItem("client_token") != undefined;
  }

  checkUserLoginOrNot() {
    if (localStorage.getItem("client_token")) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
  }
  /*delete token */
  deleteToken() {
    return this._http.delete(this.baseUrl + "logout").pipe(
      timeout(75000),
      catchError((error) => {
        throw error;
      })
    );
  }
  // User Logout
  userLogout() {
    localStorage.removeItem("client_token");
    localStorage.removeItem("client_verified");
    localStorage.removeItem('client_userData');
    localStorage.removeItem('client_setupTime');
    localStorage.removeItem('userProjectId');
    this._router.navigate(['/auth/login']);
    this.isAnyUserLoggedIn.next(false);
    this._storeService.resetStoreOnLogout();
    const currentStateOfStore = this._storeService._storeDataSource.getValue();
    if(currentStateOfStore.socialLogin){
      this.signOut();
    }
  }

  resendActivationLink(){
    return this._http.get(this.baseUrl+"resendActivationLink").pipe(timeout(75000),
    catchError((error) => {
      throw error;
    }));
  }
  // social login
  signInWithGoogle(): Promise<any> {
    return this._socialService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this._socialService.signOut();
  }
  // user enquiry
  userEnquiry(data) {
    return this._http
      .post(this.baseUrl + "client-lead", data)
      .pipe(
        timeout(75000),
        catchError((error) => {
          throw error;
        })
      );
  }

  updateServerDeviceToken(data){
    return this._http.post(this.baseUrl + "updateDeviceToken/", data).pipe(
      timeout(75000),
      catchError((error) => {
        throw error;
      })
    );
  }
  // getting response for social login user
  isUserSocialLogin(){
    return this._http.get(this.baseUrl + "is_social_login").pipe(
      timeout(75000),
      catchError((error) => {
        throw error;
      })
    );
  }
  // Get Mobile no. varifide unverified status 
  getMobileNoInfo(){
    return this._http.get(this.baseUrl + "get_is_mobile_verified").pipe(
      timeout(75000),
      catchError((error) => {
        throw error;
      })
    );
  }
}