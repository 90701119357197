import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StoreService } from '../../services/store.service';
import { Subject } from 'rxjs';
import { Store } from '../../services/models/store.model';
import { takeUntil } from 'rxjs/operators';
import { WebservicesService } from '../../services/webservices.service';
import { NotifyEventsService } from '../../services/notify-events.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UtilityService } from '../../utility/utility.service';
import { environment } from 'src/environments/environment';
import { MoengageEventService } from '../../services/moengage.service';

declare var gtag;

@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.scss']
})
export class CompanyHeaderComponent implements OnInit, OnDestroy {

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  isLoggedIn: Observable<boolean>;
  private _unsubscribe$ = new Subject<boolean>();
  userData;
  totalNotification: number = 0;
  notificationList = [];
  classToggled = false;
  isMobileVerifide = false;
  storeData;
  modalRef: BsModalRef;
  userInfo;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _storeService: StoreService,
    private _webSocketService: WebservicesService,
    public _notifyService: NotifyEventsService,
    private _modalService: BsModalService,
    private _utility: UtilityService,
    private _moengageService: MoengageEventService

  ) { }

  ngOnInit() {
    this._authService.checkUserLoginOrNot();
    this.isLoggedIn = this._authService.isLoggedIn;
    if (this._authService.verifyUserLogin()) {
      this.getNotification();
      this.getStoreData();
      this.getMobileNoInfo();
    }
  }

  getStoreData() {
    this._storeService.store$.
      pipe(
        takeUntil(this._unsubscribe$)
      ).subscribe(
        (store: Store) => {
          this.storeData = store;
          this.userData = store.userData;
          this.totalNotification = store.notificationCount;
          if (this.totalNotification != 0) {
            this.favIcon.href = 'assets/imgs/favicon-notification.ico';
          } else {
            this.favIcon.href = 'favicon.ico';
          }
          this.notificationList = store.notificationList;
          this.isMobileVerifide = store.isMobileVerifide;
          this.userInfo = store.userData;
        }
      );
  }

  toggleField() {
    this.classToggled = !this.classToggled;  
  }  
  
  // logout user
  logout() {
    this._authService.deleteToken().pipe(takeUntil(this._unsubscribe$)).subscribe({
      next: (success: any) => {
        this._authService.userLogout();
        this.modalRef.hide();
        this.favIcon.href = 'favicon.ico';
        this._utility.redirectTo('/auth/login');
        //destroy moengage session
        this._moengageService.logoutUser()
      },
      error: (error) => {
        this._utility.routingAccordingToError(error);
      }
    });
  }
  //Logout confirmation modal
  logOutModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, { class: 'lead-unlocked-modal modal-dialog-centered' });
  }
  
  getNotification() {
    this._webSocketService.notificationData.subscribe(res => {
      const currentStoreState: Store = this._storeService.getCurrentState();
      if (res.notificationDot) {
        currentStoreState.notificationCount = res.notificationDot;
      }
      if (res.notification && res.notification.length > 0) {
        currentStoreState.notificationList = res.notification;
      }
      // update store
      this._storeService.updateCompleteStore(currentStoreState);
    });
  }

  goToRoute() {
    if (this._authService.verifyUserLogin()) {
      if (this.isMobileVerifide) {
        this._router.navigate(['/dashboard']);
      } else {
        this._router.navigate(['/auth/social-signup']);
      }
    } else {
      this._router.navigate(['/']);
    }
  }
  // Get Mobile no. verified unverified status
  getMobileNoInfo() {
    this._authService.getMobileNoInfo().pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe({
      next: (response: any) => {
        this.isMobileVerifide = response.data.userData[0].is_mobile_verified;
        this._storeService.setState({
          isMobileVerifide: response.data.userData[0].is_mobile_verified
        });
        if (!this.isMobileVerifide) {
          this._router.navigate(['/auth/social-signup']);
        }
      },
      error: (error) => {
        this._utility.routingAccordingToError(error);
      }
    });
  }
  //gtag custom events
  customLoginEvent() {
    if (environment.production) {
      gtag('event', 'Login Header', {
        'event_category': 'Login Header',
        'event_action': 'Click',
        'event_label': 'Login Clicked'
      });
    };
  }

  customSignUpEvent() {
    if (environment.production) {
      gtag('event', 'Sign Up Header', {
        'event_category': 'Sign Up Header',
        'event_action': 'Click',
        'event_label': 'Sign Up Clicked'
      });
    };
  }

  customPostProjectEvent() {
    if (environment.production) {
      gtag('event', 'Post Project CTA', {
        'event_category': 'Post Project CTA',
        'event_action': 'Click',
        'event_label': 'Post Sign Up'
      });
    };

    //moengage event
    this._moengageService.projectPostStart('Hire Developers')
  }

  ngOnDestroy() {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }
}

