import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VisitorsService {

  constructor (private http: HttpClient) { }

  private key = environment.IP_REGISTRY_KEY;

  getIpAddress() {
    return this.http.get(`https://api.ipregistry.co/?key=${this.key}`).pipe(
      map((data:any)=>{
        return data;
      }),
      catchError((error: any)=>{
        throw error;
      })
    );
  }; 
}
