import { Injectable } from '@angular/core';
import { TimeoutError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  loggedIn = new BehaviorSubject<boolean>(false);
  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _authService: AuthService
  ) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage: any;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`
      );
      errorMessage = error.error.message || error.message;
    }
    throw error;
  }

  routeAccordingToError(error) {
    // console.log(error);
    if (error instanceof TimeoutError) {
      this.errorToast('Oops!', error.message);
    } else if (error.status === 0) {
      this.errorToast(
        'Oops!',
        'Please check your internet connection or try again later'
      );
    } else {
      this.errorToast('Oops!', error.error.message || error.error);
      // if verification link is not valid
      if (error.error === 'Unauthorized' || error.status === 401 || error.headers.status === 401 || error.headers.statusText === 'Unauthorized') {
        setTimeout(() => {
          this.redirectToLogin();
        }, 500);
      } else if (error.error.message === 'page under construction') {
        this._router.navigate(['/page-under-construction']);
      } else if(error.status === 404){
        if(error.error.message == 'Sorry! Agency not found.'){
          this._router.navigate(['/']);
        }else{
          this._router.navigate(['/notfound']);
        }
      } else if(error.status === 403){
        this._router.navigate(['/unverified']);
      }
    }
  }

  redirectToLogin() {
    this._authService.userLogout();
  }

  errorToast(title, detail) {
    this._toastr.error(detail, title, {
      closeButton: true,
      positionClass: 'toast-top-center',
      timeOut: 4000
    });
  }
}
