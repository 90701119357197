import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HomeService } from '../../services/home.service';
import { UtilityService } from '../../utility/utility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear;
  serviceArray = [];
  serviceId;
  private _unsubscribe$ = new Subject<boolean>();
  blogUrl: string = environment.frontendUrl+  'blog';

  constructor(
    private _service: HomeService,
    private _utility: UtilityService
  ) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.getMegaMeun();
  }

  getMegaMeun(){
    this._service.fetchMegaMenuFooter().pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe({
      next: (res:any) =>{
        this.serviceArray = res.data.dataService;
        this.serviceId = this.serviceArray[0].service_id;
      }, 
      error: (error) =>{
        this._utility.routingAccordingToError(error);
      }
    });
  }

  hoverService(id){
    this.serviceId = id;
  }

  ngOnDestroy(): void{
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }
}
