import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilityService } from '../utility/utility.service';
import { NotificationService } from './notification.service';
import { takeUntil } from 'rxjs/operators';
import { WebservicesService } from './webservices.service';
import { Store } from './models/store.model';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyEventsService {
  private subject = new BehaviorSubject(0);
  private _unsubscribe$ = new Subject<boolean>();

  currentNotice = this.subject.asObservable();
  constructor(
    private _utility: UtilityService,
    private _service: NotificationService,
    private _webSocketService: WebservicesService,
    private _storeService:StoreService,
  ) { }

  setNotificationStatus(val) {
    this.subject.next(val);
  }
    // mark as read
  markAsRead(id?, url?, type?){
    // debugger;
    let notificationId
    if(id != undefined){
      notificationId = this._utility.base64Encode(id);
    }
    const endPoint = (!id) ? this._service.readNotificaitonDone(): this._service.readNotificaitonDone(notificationId);
    endPoint.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe({
      next: (res:any) =>{
        const currentStoreState: Store = this._storeService.getCurrentState();
        if(type === 'click'){
          window.location.href = url;
        }
        this._webSocketService.notificationData.subscribe(res => {
          if (res.notificationDot) {
            currentStoreState.notificationCount = res.notificationDot;
          }
          if (res.notification && res.notification.length > 0) {
            currentStoreState.notificationList = res.notification;
          }
        });
        // if not getting any id
        if(!res.message.decoded_id){
          currentStoreState.notificationCount = 0;
        }
        // update store
        this._storeService.updateCompleteStore(currentStoreState);
      }, 
      error: (error) =>{
        this._utility.routingAccordingToError(error);
      }
    });
  }
}
