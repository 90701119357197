export class Store {
  projectData?: ProjectOnboard; 
  projectPost?: ProjectPost;
  userData?: any;
  cartData?:CartArry[];
  cartCount?:number;
  limitExceeded?:boolean = false;
  domainAndServices?:any;
  socialLogin?:boolean;
  notificationCount?:number = 0;
  notificationList?:any;
  projectFormStepCompleted?:any = 0;
  userEmailId?:string;
  projectId?:string;
  isMobileVerifide?:boolean;
  clientId?:number;
  benchPoolData?:benchPoolData;
  benchPoolFormData?:BenchpoolFormData;
  benchpoolFormStepCompleted?:any = 0;
  benchPoolRequestId:any = 0;
  showClientAvailability: boolean = false;
  userCountryCode: string;
}

export interface ProjectData {
  project_id?: any;
  domain_id: number;
  project_name: string;
  one_line_description: string;
  services: Service[];
  about_the_project: string;
  budget: string;
  payment_type: string;   
}
export interface Service {
  project_service_id?: any;
  service_id: number;
}
export interface UserData {
  user_name: string;
  user_profile: string;
}
interface CartArry {
  company_id: string;
  project_id: string;
  company_details: Companydetails;
}
interface Companydetails {
  company_id: number;
  company_name: string;
  logo: string;
  director_name: string;
  company_email_id: string;
  company_website: string;
  contact_number: string;
  overall_rating?: string;
  address: Address[];
}
export interface Address {
  location?: string;
}
export interface ProjectPost {
  domain_id: number;
  category: string;
  project_name: string;
  one_line_description: string;
  services: ServiceNew[];
  skills: any[];
  about_the_project: string;
  budget: string;
  payment_type: string;
  user_id: number;
  domain_experience_in_months: number;
  company_expected_experience: number;
  status: string;
  created_at: string;
  project_id: number;
}
export interface ServiceNew {
  service_id: number;
  project_id: number;
  project_service_id: number;
}
interface ProjectOnboard {
  project_id?: any;
  project_name: string;
  about_the_project: string;
  budget: string;
  expected_timeline: string;
  domain_id: number;
  project_expertise:[];
  domain_experience_in_months: number;
  services: [];
  skills: [];
}
// Benchpool leads modal for store 
export interface benchPoolData {
  designation: string;
  client_id: number;
  no_of_developer_required: string;
  developer_experience: string;
  contract_period: number;
  availability_to_start: string;
  billing_type: string;
  budget: string;
  contract_period_extension: boolean;
  skills: Skill[];
  language: string;
}
// skills related to benchpool 
interface Skill {
  developer_request_techskill_id?: any;
  skill_id: number;
}
export interface BenchpoolFormData {
  project_name: string;
  role: any[];
  skills: any[];
  budget:string;
  contract_period: number;
  availability_to_start: string;
}