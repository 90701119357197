import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/header/header.component';
import { StoreService } from '../../services/store.service';
import { UtilityService } from '../../utility/utility.service';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {

  userData;

  constructor(
    private _storeService: StoreService,
    private _utility: UtilityService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('client_userData')) {
      this.userData = JSON.parse(this._utility.base64Decode(localStorage.getItem('client_userData')));
      this._storeService.setState({
        userData: this.userData
      })
    }
    this._utility.hideCrispChat();
  }
}
