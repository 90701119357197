<!--New header 27 Dec 2021-->
<header class="new-header pt-0 pb-0">
    <div class="container client-header-container">
        <div class="row align-items-center">
            <div class="col-3 col-sm-3 col-md-3 col-lg-3">
                <a class="logo" (click)="goToRoute()"><img alt="Supersourcing" width="184" src="assets/imgs/logo-dark.svg"></a>
            </div>

            <div class="col-3 col-sm-6 col-md-6 col-lg-4 d-none d-sm-block">
                <div class="center-menu text-center">
                    <ul>
                        <li>
                            <a routerLink="/dashboard" routerLinkActive="active" fragment="fresh-leads">
                                <img src="assets/imgs/document.svg" width="16" class="icon-left-img" alt="Projects"> Projects
                            </a>
                        </li>
                        <li>
                            <a routerLink="/testimonial" routerLinkActive="active">
                                <img src="assets/imgs/volume-2.svg" width="16" class="icon-left-img" alt="Reviews"> Testimonial
                            </a>
                        </li>
                    </ul>
                </div>
            </div>


            <div class="col-8 col-sm-3 col-md-3 col-lg-5">
                <div class="notification-profile py-4">
                    <ul class="d-flex justify-content-end align-items-center" *ngIf="(isLoggedIn | async)">
                        <li><a (click)="customPostProjectEvent()" [routerLink]="['/hiring-developers/basic-details']" class="button-main button-blue button-bordered text-nowrap client-hire-engineers-btn">Hire Engineers</a></li>
                        <li class="dropdown notification-nav ms-5">
                            <a href="javascript:void(0)" class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="assets/imgs/icon-notification.svg" width="16" alt="Notification">
                                <span *ngIf="totalNotification > 0" class="notification-numbers">{{totalNotification > 50 ? '50+' : totalNotification}}</span>
                            </a>
                            <div class="dropdown-menu notification-dropdown-menu notification-dropdown-mobile-adjust p-4" aria-labelledby="dropdownMenuButton1">
                                <h4 class="fs-lg color-black fw-bold">Notification ({{totalNotification || 0}})</h4>
                                <ul class="mt-4 block-scroller">
                                    <li class="c-pointer d-flex" *ngFor="let notify of notificationList" [ngClass]="{'new': !notify?.read_status && totalNotification != 0}">
                                        <a class="link" *ngIf="notify?.redirect_url || notify?.read_status == false" (click)="_notifyService.markAsRead(notify?.notification_id, notify?.redirect_url, 'click')"></a>
                                        <figure><img src="assets/imgs/icon-notification.svg" width="16" alt="Notification"></figure>
                                        <div class="text ps-3">
                                            <p class="mt-1 color-grey fs-xs" [innerHTML]="notify.message"></p>
                                            <span class="color-grey fs-xs">{{notify.created_at | dateAgo}}</span>
                                        </div>
                                    </li>
                                </ul>
                                <div class="empty-notification text-center" *ngIf="!notificationList">
                                    <figure><img src="assets/imgs/empty-state-notification.svg" alt="Empty Notification"></figure>
                                    <h4 class="fs-md color-black fw-extrabold mt-4">No notifications yet!</h4>
                                    <p class="fs-sm color-grey mt-2">We will notify you once we have something for you</p>
                                </div>
                                <div class="pt-4 border-top d-flex n-btns justify-content-between" *ngIf="notificationList">
                                    <button class="button-main button-blue button-bordered border-0 text-nowrap ms-2" (click)="_notifyService.markAsRead()">Mark all as read</button>
                                    <button class="button-main button-blue button-bordered min-width-150 me-3" [routerLink]="['/notification']">View All</button>
                                </div>
                            </div>
                        </li>
                        <li class="ms-4 ps-4 border-start dropdown profile-nav">
                            <a href="javascript:void(0)" class="d-flex dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <figure><img onerror="this.src = 'assets/imgs/login-signup/avatar.svg'" [src]="userData?.user_profile" alt="Logo"></figure>
                                <h5 class="fs-sm fw-semibold color-black ps-3 text-truncate">{{userInfo?.fullName}}
                                    <span class="d-block fs-xxs fw-normal color-grey mt-1 text-truncate">{{userInfo?.designation}}</span>
                                </h5>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <!-- <li *ngIf="isMobileVerifide">
                                    <a class="dropdown-item" [routerLink]="['/my-profile']"><img src="assets/imgs/user.svg" width="16" class="icon-left-img" alt="profile"> My Profile</a>
                                </li> -->
                                <li *ngIf="isMobileVerifide">
                                    <a class="dropdown-item" [routerLink]="['/setting']"><img src="assets/imgs/setting.svg" width="16" class="icon-left-img" alt="profile"> Settings</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:void(0)" (click)="logOutModal(logOut)"><img src="assets/imgs/icon-logout.svg" width="16" class="icon-left-img" alt="Log out">Log out</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-1 d-inline-block d-sm-none text-center">
                <a class="toggle-menu-mobile" id="sidebarToggle" (click)="toggleField()">
                    <img src="assets/imgs/menu.svg" width="22" alt="Menu">
                </a>
                <div class="navbar-menu main-menu dropdown-menu-main text-start" [class.open]="classToggled">
                    <span class="close-left-menu d-block text-end p-4 pt-3 bg-white" (click)="toggleField()">&#10006;</span>
                    <ul class="bg-white">
                        <li class="p-4 border-top">
                            <div class="d-flex">
                                <figure><img class="rounded-circle border" onerror="this.src = 'assets/imgs/login-signup/avatar.svg'" [src]="userData?.user_profile" alt="Logo" width="38px" height="38px"></figure>
                                <h5 class="fs-md fw-semibold color-black ps-3 text-truncate">{{userInfo?.fullName}}
                                    <span class="d-block fs-xxs fw-normal color-grey mt-1 text-truncate">{{userInfo?.designation}}</span>
                                </h5>
                            </div>
                        </li>
                        <li class="p-4 border-top">
                            <a routerLink="/dashboard" class="color-black d-block fs-md" (click)="toggleField()" routerLinkActive="active" fragment="fresh-leads">
                                <img src="assets/imgs/document.svg" width="16" class="icon-left-img me-2" alt="Projects">Projects
                            </a>
                        </li>    
                        <li class="p-4 border-top">
                            <a routerLink="/testimonial" class="color-black d-block fs-md" (click)="toggleField()" routerLinkActive="active">
                                <img src="assets/imgs/volume-2.svg" width="16" class="icon-left-img me-2" alt="Reviews">Testimonial
                            </a>
                        </li>    
                        <li class="p-4 border-top" *ngIf="isMobileVerifide">
                            <a [routerLink]="['/setting']" class="color-black d-block fs-md" (click)="toggleField()" routerLinkActive="active">
                                <img src="assets/imgs/setting.svg" width="16" class="icon-left-img me-2" alt="profile">Settings
                            </a>
                        </li>
                        <li class="p-4 border-top">
                            <a class="color-black d-block fs-md" (click)="logOutModal(logOut)">
                                <img src="assets/imgs/icon-logout.svg" width="16" class="icon-left-img me-2" alt="Log out">Log out
                            </a>
                        </li>
                    </ul>
                </div>
            </div>


            <!-- <div class="col-6 col-sm-6 col-md-6 col-lg-4 d-block d-sm-none">
                <div class="center-menu text-center">
                    <ul>
                        <li>
                            <a routerLink="/dashboard" routerLinkActive="active" fragment="fresh-leads">
                                <img src="assets/imgs/document.svg" width="16" class="icon-left-img" alt="Projects"> Projects
                            </a>
                        </li>
                        <li>
                            <a routerLink="/testimonial" routerLinkActive="active">
                                <img src="assets/imgs/volume-2.svg" width="16" class="icon-left-img" alt="Reviews"> Testimonial
                            </a>
                        </li>
                    </ul>
                </div>
            </div> -->

        </div>
    </div>
</header>

<ng-template #logOut>
    <div class="modal-body text-center p-5 text-center">
        <h3 class="color-black fs-xl fw-medium">Logout</h3>
        <p class="mt-3 color-grey fs-xs">Are you sure you want to log out from supersourcing?</p>
        <div class="text-center mt-5">
            <button class="button-main button-blue button-bordered" (click)="modalRef.hide()">Cancel</button>
            <button class="button-main button-red" (click)="logout()">Yes, Logout</button>
        </div>
    </div>
</ng-template>