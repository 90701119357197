import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import $ from 'jquery';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HomeService } from '../../services/home.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilityService } from '../../utility/utility.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { CASE_STUDY_PAGE_LABEL } from 'src/app/case-study/case-study-page-label';

declare var gtag;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private _unsubscribe$ = new Subject<boolean>();
  companySignUpURL: string = environment.frontendUrl + 'agency/auth/signup';
  companyLoginURL: string = environment.frontendUrl + 'agency/auth/login';
  individualDeveloperLoginUrl: string = environment.individual_developer + 'auth/login';
  individualDeveloperSignupUrl: string = environment.individual_developer + 'auth/signup';
  blogUrl: string = environment.frontendUrl + 'blog'
  classToggled = false;
  industryId: number;
  industryArray = [];
  modalRef: BsModalRef;
  serviceMenuList = [];
  serviceId;
  caseStudyId;
  currentRoute: any;
  caseStudyLabel: any;
  isAnyUserLoggedIn: boolean = false;
  caseStudyPagesRoutes = CASE_STUDY_PAGE_LABEL;

  staticPageRoutes = [
    { label: 'Hire Python Developers', route: '/hire-python-developers' },
    { label: 'Hire ReactJs Developers', route: '/hire-reactjs-developers' },
    { label: 'Hire NodeJs Developers', route: '/hire-nodejs-developers' },
    { label: 'Hire AngularJs Developers', route: '/hire-angularjs-developers' },
    { label: 'Hire React Native Developers', route: '/hire-react-native-developers' },
    { label: 'Hire Magento Developers', route: '/hire-magento-developers' },
    { label: 'Hire Wordpress Developers', route: '/hire-wordpress-developers' },
    { label: 'Hire Flutter Developers', route: '/hire-flutter-developers' },
    { label: 'Hire Kotlin Developers', route: '/hire-kotlin-developers' },
    { label: 'Hire Shopify Developers', route: '/hire-shopify-developers' },
    { label: 'Hire Xamarin Developers', route: '/hire-xamarin-developers' },
    { label: 'Hire Ionic Developers', route: '/hire-ionic-developers' },
    { label: 'Hire AdobeXD Designers', route: '/hire-adobe-xd-designers' },
    { label: 'Hire Photoshop Designers', route: '/hire-photoshop-designers' },
    { label: 'Hire DevOps Developers', route: '/hire-devops-developers' },
    { label: 'Hire UX Developers', route: '/hire-ux-developers' },
    { label: 'Hire MEAN Stack Developers', route: '/hire-mean-developers' },
    { label: 'Hire Full Stack Developers', route: '/hire-fullstack-developers' },
    { label: 'Hire Salesforce Developers', route: '/hire-salesforce-developers' },
    { label: 'Hire MERN Developers', route: '/hire-mern-developers' },
    { label: 'Hire UI Developers', route: '/hire-ui-developers' },
    { label: 'Hire IOS Developers', route: '/hire-ios-developers' },
    { label: 'Hire PHP Developers', route: '/hire-php-developers' },
    { label: 'Hire Java Developers', route: '/hire-java-developers' },
    { label: 'Hire Cloud Developers', route: '/hire-cloud-developers' },
    { label: 'Hire Blockchain Developers', route: '/hire-blockchain-developers' },
    { label: 'Hire Data Analyst Developers', route: '/hire-data-analyst-developers' },
    { label: 'Hire Metaverse Developers', route: '/hire-metaverse-developers' },
    { label: 'Hire QA Developers', route: '/hire-qa-developers' }
  ];

  changeBtn:any;
  constructor(
    public _router: Router,
    private _service: HomeService,
    private _utility: UtilityService,
    private _authService: AuthService,
    private _activetedRaoute: ActivatedRoute,
    private _homeService: HomeService,
    @Inject(DOCUMENT) private _document
  ) { 
    
  }

  ngOnInit() {
    let a  = this._homeService.changeButtonStatus;
    a.subscribe((res:any) =>{
      this.changeBtn = res;
    })
  
    this.getCurrentRoute();
    this.getMegaMeun();
    this.hoverCaseStudy(1, 'Saas');
    let checkRoute = this._activetedRaoute.snapshot['_routerState'].url;
    
    
    // Hide login and signup dropdown if user is logged in
    this._authService.isAnyUserLoggedIn.subscribe(res => {
      
      this.isAnyUserLoggedIn = res;
    });
    // const triggerMenu = document.querySelector(".static-header");
    // const scrollUp = "sticky";
    // const scrollDown = "sticky-down";
    // let lastScroll = 0;

    // window.addEventListener("scroll", () => {
    //   const currentScroll = window.pageYOffset;
    //   if (currentScroll <= 0) {
    //     triggerMenu.classList.remove(scrollUp);
    //     return;
    //   }

    //   if (currentScroll > lastScroll && !triggerMenu.classList.contains(scrollDown)) {
    //     // down
    //     triggerMenu.classList.remove(scrollUp);
    //     triggerMenu.classList.add(scrollDown);
    //   } else if (
    //     currentScroll < lastScroll &&
    //     triggerMenu.classList.contains(scrollDown)
    //   ) {
    //     // up
    //     triggerMenu.classList.remove(scrollDown);
    //     triggerMenu.classList.add(scrollUp);
    //   }
    //   lastScroll = currentScroll;
    // });

    $(document).ready(function () {
      // class add on scroll (header)
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 1) {
          $(".header").addClass("sticky");
        } else {
          $(".header").removeClass("sticky");
        }
      });
    });


    $(function () {

      $('.dropdown-li a:not(:only-child)').click(function (e) {
        $(this).siblings('.dropdown-navigation').toggleClass('show');

        $('.dropdown-navigation').not($(this).siblings()).removeClass('show');
        e.stopPropagation();
      });

      $('html').click(function () {
        $('.dropdown-navigation').removeClass('show');
      });
    });
  }
  getCurrentRoute(): void {
    this.currentRoute = this._router.url.split('?')[0];
    this._router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url.split('?')[0];
      });
  }

  scrollToGetQuote(): void {
    let quoteForm: HTMLElement = this._document.querySelector('.quoteForm');
    quoteForm.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }

  getMegaMeun() {
    this._service.fetchMegaMenuBasedOnService().pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe({
      next: (res: any) => {
        this.serviceMenuList = res.data.dataService;
        // 
        this.industryArray = res.data.dataService;
        this.serviceId = this.serviceMenuList[0].service_id;
        // 
      },
      error: (error) => {
        this._utility.routingAccordingToError(error);
      }
    });
  }

  toggleField() {
    this.classToggled = !this.classToggled;
  }

  hoverIndustry(id) {
    this.industryId = id;
  }

  hoverService(id) {
    this.serviceId = id;
  }

  hoverCaseStudy(id, label) {
    this.caseStudyId = id;
    this.caseStudyLabel = label;
  }

  navigateCaseStudy(router) {
    this._router.navigate([router])
      .then(() => {
        window.location.reload();
      });
  }
  //Custom Login Events
  customLoginAsClientEvent() {
    if (environment.production) {
      gtag('event', 'Login', {
        'event_category': 'Login',
        'event_action': 'Click',
        'event_label': 'Login as Client'
      })
    }
  }

  customLoginAsTPEvent() {
    if (environment.production) {
      gtag('event', 'Login', {
        'event_category': 'Login',
        'event_action': 'Click',
        'event_label': 'Login as Talent Partner'
      });
    }
  }
  //Custom Sign up Events
  customSignUpAsClientEvent() {
    if (environment.production) {
      gtag('event', 'Sign Up', {
        'event_category': 'Sign Up',
        'event_action': 'Click',
        'event_label': 'Sign up as Client'
      });
    }
  }

  customSignUpAsTPEvent() {
    if (environment.production) {
      gtag('event', 'Sign Up', {
        'event_category': 'Sign Up',
        'event_action': 'Click',
        'event_label': 'Sign up as Talent Partner'
      });
    }
  }

 

  ngOnDestroy() {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }
}
