import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip'

@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot()
    ],
    exports: [
        BsDropdownModule,
        ModalModule,
        TabsModule,
        TooltipModule
    ],
    declarations: []
  })
  export class NgxBootstrapModule { }