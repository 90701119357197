import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { timeout, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseUrl: string;
  constructor(
    private _baseService: BaseService,
    private _http: HttpClient,
    private _errorHandler: ErrorHandlerService
  ) {
    this.baseUrl = this._baseService.baseUrl;
  }

  getAllNotification(offset?){
    var limit=10
    return this._http.get(`${this.baseUrl}notification?offset=${offset}&limit=${limit}`).pipe(
      timeout(75000),
      catchError((error) => {
        this._errorHandler.routeAccordingToError(error);
        throw error;
      })
    );
  }

  readNotificaitonDone(id?){
    const endPoint = (!id)?(`readNotification`) : (`readNotification/${id}`);
   return this._http.get(this.baseUrl + endPoint)
   .pipe(
     timeout(75000),
     catchError((error) => {
       this._errorHandler.routeAccordingToError(error);
       throw error;
     })
   );
  }
  //  make read
  clearAllNotification(id?){
    const endPoint = (!id)?(`clearNotification`) : (`clearNotification/${id}`);
    return this._http.get(this.baseUrl + endPoint)
    .pipe(
      timeout(75000),
      catchError((error) => {
        this._errorHandler.routeAccordingToError(error);
        throw error;
      })
    );
  }
}
