<header class="header static-header sticky">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="d-flex justify-content-between align-items-center">
                    <a class="logo"><img src="assets/imgs/logo-dark.svg" alt="Supersourcing"></a>
                    <!-- <button class="button-main button-blue" (click)="scrollToTop()">Try Supersourcing For Free</button> -->
                    <button class="button-main button-blue py-3" (click)="scrollToTop()">Try Supersourcing For
                        Free</button>
                </div>
            </div>
        </div>
    </div>
</header>