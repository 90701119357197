import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../../utility/utility.service';

@Component({
  selector: 'app-marketing-layout',
  templateUrl: './marketing-layout.component.html',
  styleUrls: ['./marketing-layout.component.scss']
})
export class MarketingLayoutComponent implements OnInit {

  constructor(
    private _utility: UtilityService
  ) { }

  ngOnInit(): void {
    this._utility.createRobotsMetaTag();
    this._utility.hideCrispChat();
  }

  ngOnDestroy(): void {
    this._utility.removeRobotsMetaTag();
  }

}
