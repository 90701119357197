import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var gtag;

type event = 'Manual' | 'Google';

@Injectable({
  providedIn: 'root'
})
export class GooogleAnalyticsService {

  constructor() { }

  clientLoginSuccess(type: event) {
    gtag('event', 'Login Success', {
      'event_category': 'Login Success',
      'event_action': type,
      'event_label': 'Login as Client'
    });
  }

  clientSignupSuccess(type: event) {
    gtag('event', 'Sign Up Success', {
      'event_category': type == 'Google' ? 'Sign Up Success' : 'BP Client SP Success',
      'event_action': type,
      'event_label': 'Sign Up as Client'
    });
  }

  leadFormSubmitted(leadType: string): void {
    if (environment.production) {
      gtag('event', 'Lead Submitted', {
        'event_category': 'Lead Submitted',
        'event_action': 'Click',
        'event_label': leadType + ' ' + 'Developer'
      });
    }
  }
}