import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketing-footer',
  templateUrl: './marketing-footer.component.html',
  styleUrls: ['./marketing-footer.component.scss']
})
export class MarketingFooterComponent implements OnInit {

  currentYear;
  constructor() { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

}
