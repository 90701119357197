<section class="notfound">
    <img class="top-image" src="assets/imgs/hero-top.svg" alt="Graph">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="content">
                    <img src="assets/imgs/404.svg" alt="404">
                    <h2>Oops! Page not Found</h2>
                    <p>The page you are looking for might have been removed or <br>its temporarily unavailable.</p>
                    <a (click)="routeTo()" class="main-btn blue-btn">Go back home</a>
                </div>
            </div>
        </div>
    </div>
</section>