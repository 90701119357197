import { Inject, Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorHandlerService } from '../services/error-handler.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';

declare const $crisp;
type allowCharactersType = 'alphaNumeric' | 'alphaNumericWithoutSpace' | 'numberWithoutSpace' | 'customPattern';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public passwordRegex = new RegExp(/^(?=.{6,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/);

  constructor(
    private loader: NgxUiLoaderService,
    private errorHandler: ErrorHandlerService,
    private toast: ToastrService,
    private _router: Router,
    private _location: LocationStrategy,
    private _modalService: BsModalService,
    private _activatedRoute: ActivatedRoute,
    private _domSanitizer: DomSanitizer,
    @Inject(DOCUMENT) private _document
  ) { }

  /**
   * Scroll to the top of the page
   */
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  /**
   * Add this function to an input tag with input, keyup, keydown, keypress event to get only number as input
   * @param event
   * @returns True if user input is number
   * @returns False if user input is not a number
   */
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && charCode !== 43 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * Stops the loader and scrolls the page to the top
   */

  resetPage(): void {
    this.loader.stop();
    this.scrollToTop();
  }

  /**
   * Starts the UI loader
   */
  loaderStart(): void {
    this.loader.start();
  }

  /**
   * Stops the UI loader
   */
  loaderStop(): void {
    this.loader.stop();
  }

  /**
   * Error handler that takes error object and routes to respective page according to error status code
   * @param error - Error object
   */
  routingAccordingToError(error): void {
    this.errorHandler.routeAccordingToError(error);
    this.resetPage();
  }

  /**
   * Displays success (Green) toast on the top center of the browser window
   * @param title Title of the message
   * @param details Details of the message
   */
  toastSuccess(title: string, details: string): void {
    this.toast.success(details, title, {
      closeButton: true,
      positionClass: 'toast-top-center',
      // timeOut: null
      timeOut: 1500
    });
  }

  /**
   * Displays info (Blue) toast on the top center of the browser window
   * @param title Title of the message
   * @param details Details of the message
   */
  toastInfo(title: string, details: string): void {
    this.toast.info(details, title, {
      closeButton: true,
      positionClass: 'toast-top-center',
      // timeOut: null
      timeOut: 1500
    });
    this.resetPage();
  }

  /**
   * Displays warning (Yellow) toast on the top center of the browser window
   * @param title Title of the message
   * @param details Details of the message
   */
  toastWarning(title: string, details: string): void {
    this.toast.warning(details, title, {
      closeButton: true,
      positionClass: 'toast-top-center',
      timeOut: 4000
    });
  }
  /**
   * Displays error (Red) toast on the top center of the browser window
   * @param title Title of the message
   * @param details Details of the message
   */
  toastError(title: string, details: string): void {
    this.toast.error(details, title, {
      closeButton: true,
      positionClass: 'toast-top-center',
      timeOut: 4000
    });
  }

  /**
   * 
   * @param stringText Value to encode
   * @returns encoded string
   */
  base64Encode(stringText: any): string {
    return window.btoa(stringText);
  }

  /**
   * 
   * @param stringText Type: base64 encoded string
   * @returns decoded string
   */
  base64Decode(stringText): string {
    return window.atob(stringText);
  }

  /**
   * 
   * @param event - Should have pattern key in input field if 'customPattern' is selected
   * @param type - "alphaNumeric" | "alphaNumericWithoutSpace" | "numberWithoutSpace" | "customPattern"
   * @returns False - If input value does not match with the type parameter
   */
  allowCharacters(event, type: allowCharactersType): boolean {
    let pattern;
    switch (type) {
      case 'alphaNumeric':
        pattern = /^[a-zA-Z0-9]/;
        break;
      case 'alphaNumericWithoutSpace':
        pattern = /^\S[a-zA-Z0-9]*$/;
        break;
      case 'numberWithoutSpace':
        pattern = /^\S[0-9]*$/;
        break;
      case 'customPattern':
        pattern = new RegExp(event.target.pattern);
        break;
      default:
        pattern = "";
    }
    let next: string;

    let current: string = event.target.value;
    next = current.concat(event.key);

    if (event.keyCode != 8 && event.keyCode != 9 && event.keyCode != 13 && event.keyCode != 16 && event.keyCode != 20) {
      if (!pattern.test(next)) {
        return false
      }
    }
  }

  /**
   * Takes youtube video url and returns the video id
   * @param url Youtube video url
   * @returns video id
   * @returns error - If youtube input url is not valid
   */
  getYoutubeVideoId(url: string): string {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return 'error';
    }
  }

  /**
   * Takes Dailymotion video url and returns the video id
   * @param str - Dailymotion video URL
   * @returns - Dailymotion video ID
   */
  getDailyMotionId(str: string): any {
    let ret = [];
    let re = /(?:dailymotion\.com(?:\/video|\/hub)|dai\.ly)\/([0-9a-z]+)(?:[\-_0-9a-zA-Z]+#video=([a-z0-9]+))?/g;
    let m;

    while ((m = re.exec(str)) != null) {
      if (m.index === re.lastIndex) {
        re.lastIndex++;
      }
      ret.push(m[2] ? m[2] : m[1]);
    }
    return ret;
  }

  /**
   * Takes star rating value and returs array of boolean values respective to input star rating
   * @param value - Star Rating
   * @returns Array of boolean values
   */
  getStars(value: number): Array<boolean> {
    let totalStarArray = [1, 2, 3, 4, 5];
    let stars = [];
    totalStarArray.forEach((element) => {
      if (value != null && value != 0)
        stars.push(element <= value)
      else
        stars.push(false)
    });
    return stars;
  }

  /**
   * Takes Date as string and returns experience in Years
   * @param value Date string
   * @returns Number of experience in years
   */
  calculateExperienceYear(value: string): string {
    let todayYear = new Date().getFullYear();
    let estiblishedYear = new Date(value).getFullYear();
    let totalYear = todayYear - estiblishedYear;

    if (totalYear > 1) {
      return totalYear + " years";
    } else if (totalYear == 1) {
      return totalYear + " year";
    } else {
      return "Less than 1 year"
    }
  }

  /**
   * Redirects to input url
   * @param uri Target absolute url
   */
  redirectTo(uri: string): void {
    this._router.navigateByUrl('/blank', { skipLocationChange: true }).then(() =>
      this._router.navigate([uri]));
  }

  /**
   * Trims the value of formController
   * @param formController Form Control value to be trimmed
   */
  removeInputSpace(formController: AbstractControl): void {
    if (formController.value) {
      formController.setValue(formController.value.trim());
    }
  }

  /**
   * Disables browser back button by clearing browser's cache
   */
  skipBack(): void {
    history.pushState(null, null, window.location.href);
    this._location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  /**
   * Returns project timeline in string
   * @param timeline "30" | "90" | "180" | "360"
   * @returns "1 to 2 months" | "2 - 4 months" | "5 - 6 months" | "More than 6 months"
   */
  getTimeline(timeline): string | null {
    const value = timeline;
    switch (true) {
      case value === '30':
        return '1 to 2 months';
      case value === '90':
        return '2 - 4 months';
      case value === '180':
        return '5 - 6 months';
      case value === '360':
        return 'More than 6 months';
      default:
        return null;
    }
  }

  /**
   * Creates canonical tag in html {@link Document}
   */
  createLinkForCanonicalURL(): void {
    let link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this._document.head.appendChild(link);
    link.setAttribute('href', this._document.URL);
  }
  /**
   * Removes canonical tag in html {@link Document}
   */
  removeCanonicalURL(): void {
    const canonical: any = this._document.querySelectorAll('link[rel="canonical"]');
    canonical[0].parentElement.removeChild(canonical[0]);
  }

  /**
   * Returns formatted service name with spaces replaced with hyphens
   * @param value Service Name
   * @returns formatted service name
   */
  getServiceIcons(value: String): string {
    return value.replace(/( & | |-|\/)/g, '').toLowerCase();
  }

  /**
   * Returns formatted domain name with spaces replaced with hyphens
   * @param value domain Name
   * @returns formatted domain name
   */
  getDomainIcon(value: String): string {
    return value.replace(/( & | |-)/, '').toLowerCase();
  }

  /**
   * Implements Math.round()
   * @param value Value to be rounded
   * @returns Rounded value
   */
  mathRound(value: number): number {
    return Math.round(value);
  }

  /**
   * Checks if user is browsing in PC or Mobile devices
   * @returns True - if user is browsing in PC device
   * @returns False - if user is browsing in Mobile device
   */
  checkIfWebUser(): boolean {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Hides header on navigation when links are accessed from navbar in mobile devices
   */
  removeClassFromHeader(): void {
    let header = this._document.querySelector('.navbar-menu') as HTMLElement;
    // close header if open
    if (header && header.classList.contains('open')) {
      header.classList.remove('open');
    }
  }

  /**
   * Creates {@link object} hard copy
   * @param data Object to copy
   * @returns Hard copy of input object
   */
  createObjCopy(data): any {
    return JSON.parse(JSON.stringify(data));
  }

  /**
   * Returns list of skills required in project
   * @param project Project Object
   * @returns Array of skills
   */
  getSkills(project): any {
    let roles = project?.rolesRequested;
    let skillList = {};
    roles.forEach(role => {
      skillList[role?.roles] = role?.skillData.split(',');
    });
    return skillList;
  }

  /**
   * Opens crisp chat widget
   */
  openCrispChat(): void {
    $crisp.push(['do', 'chat:open']);
  }

  /**
   * Shows crisp chat widget
   */
  showCrispChat(): void {
    $crisp.push(["do", "chat:show"]);
  }

  /**
   * Hides crisp chat widget
   */
  hideCrispChat(): void {
    $crisp.push(["do", "chat:hide"]);
  }

  /**
   * Generates random password with atleast 1 lowercase & uppercase letter, 1 digit and 1 symbol
   * @param formController Form Control to which the generated password is set
   */
  autoGeneratePassword(formController: AbstractControl): void {
    const length = this.getNumberInRange(6, 12);
    let result = '';
    for (let index = 1; index <= length; index++) {
      result += this.getRandomCharacter();
    }
    if (!result.match(this.passwordRegex)) {
      this.autoGeneratePassword(formController);
    } else {
      formController.setValue(result);
    }
  }

  /**
   * Generates random number within provided minimum and maximum number
   * @param min Minimum number in range
   * @param max Maximum number in range
   * @returns Random number within provided range
   */
  getNumberInRange(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  /**
   * @returns Random generated character
   */
  getRandomCharacter(): number {
    const obj = {
      0: 'abcdefghijklmnopqrstuvwxyz',
      1: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      2: '!@#$%^&*',
      3: '0123456789'
    };
    const key = Math.floor(Math.random() * 4);
    let max = 0;
    if (key == 2) {
      max = 7;
    } else if (key == 3) {
      max = 9;
    } else {
      max = 25;
    }
    return obj[key][this.getNumberInRange(0, max)];
  }

  /**
   * Buypass security for provided url
   * @param url Website url
   * @returns Safe resource url
   */
  bypassSecurityTrustResourceUrl(url: string) {
    return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   * 
   * @returns True - if url contains "ad/" else False
   */
  checkMarketingRoute() {
    if (this._router.url.includes('ad/')) {
      return true;
    }
    return false;
  }

  /**
   * Creates html meta tag
   * <meta name="robots" content="noindex, nofollow">
   */
  createRobotsMetaTag(): void {
    let meta: HTMLLinkElement = this._document.createElement('meta');
    meta.setAttribute('name', 'robots');
    meta.setAttribute('content', 'noindex, nofollow');
    this._document.head.appendChild(meta);
  }

  /**
   * Removes meta tag
   * <meta name="robots" content="noindex, nofollow">
   */
  removeRobotsMetaTag(): void {
    const robots: any = this._document.querySelectorAll('meta[content="noindex, nofollow"]');
    robots[0].parentElement.removeChild(robots[0]);
  }

  /**
   * Copies input value to clipboard
   * @param value Value to copy
   */
  async copyToClipboard(value) {
    try {
      await navigator.clipboard.writeText(value);
      this.toastSuccess(null, 'Copied to Clipboard!');
    } catch (error) {
      this.toastError('Oops!', error.error.message);
    }
  }

  openModalDialogCentered(template?: any, ignoreBackdropClick = false, keyboard = true) {
    return this._modalService.show(template, {
      class: "lead-unlocked-modal modal-dialog-centered",
      ignoreBackdropClick: ignoreBackdropClick,
      keyboard: keyboard,
    });
  }

}


