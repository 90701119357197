import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { of, tap } from 'rxjs';
import { CacheService } from '../services/cache.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  private baseUrl = environment.baseUrl;

  constructor(private cache: CacheService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    if (!this.isRequestCachable(req)) {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req);
    
    if (cachedResponse) {
      return of(cachedResponse);
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.set(req, event);
        }
      })
    );
  }

  private isRequestCachable(req: HttpRequest<any>) {
    if(req.method === 'GET'){
      return true;
    }else{
      if(req.method === 'POST' || req.method === 'DELETE'){
        this.cache.cacheMap.clear();
      }      
      return false;
    }
  }
}
