import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptor } from './core/interceptor/token-interceptor.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SocialLoginModule, GoogleLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
// import {IntercomModule} from 'ng-intercom';
import { environment } from 'src/environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CacheInterceptor } from './core/interceptor/cache.interceptor';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: 'ball-scale-multiple',
};

// let config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider("12125803069-0u06c640c9rjaj22iq9l36b7guqsakvb.apps.googleusercontent.com")
//   }
// ]);
// export function provideConfig() {
//   return config;
// } 
const baseUrl = environment.baseUrl;
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    CoreModule,
    BrowserAnimationsModule,
    GoogleTagManagerModule.forRoot({
      id: "G-RN4PTDB7XB",
    }),
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true, exclude: [
        baseUrl + 'addCompanyRules',
        baseUrl + 'fetchCountryStateCityLocation',
        baseUrl + 'send-message',
        baseUrl + 'load-chats',
        baseUrl + 'get-my-project-list',
        baseUrl + 'getRecommendationForMyProject_v2',
        baseUrl + 'my-inbox-list-v2',
        baseUrl + 'my-chats',
        baseUrl + 'getSkillList',
        baseUrl + 'search-recommended-developers',
        baseUrl + 'fetch-filter-list-in-recommendation',
        baseUrl + 'fetch-dashboard-benchpool-recommendation',
        baseUrl + 'get_is_mobile_verified',
        baseUrl + 'notification',
        baseUrl + 'readNotification',
        baseUrl + 'agency-public-profile-review',
        'https://api.ipregistry.co/?key=o3twq3zjhuq4stvs'
      ]
    }),
    SocialLoginModule,
    // IntercomModule.forRoot({
    //   appId: 'kz7l17cr',
    //   updateOnRouterChange: true
    // }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [ 
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },
    // OLD PROVIDER VERSION
    // { provide: 'googleTagManagerId', useValue: 'G-RN4PTDB7XB' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "12125803069-0u06c640c9rjaj22iq9l36b7guqsakvb.apps.googleusercontent.com"
            )
          }
        ]
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})


export class AppModule {}
