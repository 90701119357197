export interface caseStudyLabel {
    label: string,
    route: string,
    image: string,
    caseStudyId: number,
    submenu?: submenu[]
}

export interface submenu {
    companyName: string,
    image: string,
    route?: string,
    companyId?: number
}
const imgPath: string = '../../../../assets/imgs/case-study-img/';
export const CASE_STUDY_PAGE_LABEL: caseStudyLabel[] = [
    {
        label: 'SaaS', route: '', image: imgPath + '/saas.svg',
        caseStudyId: 1,
        submenu: [
            { companyName: 'Interview kick start', image: imgPath + '/saas.svg', route: '/interview-kick-start' },
            { companyName: '4thoughtglobal', image: imgPath + '/saas.svg', route: '/4thoughtglobal' },
            { companyName: 'Integris', image: imgPath + '/saas.svg', route: '/integris' }
        ]
    },
    {
        label: 'FinTech', route: '', image: imgPath + '/fin-tech.svg',
        caseStudyId: 2,
        submenu: [
            { companyName: 'Ok credit', image: imgPath + '/fin-tech.svg', route: '/ok-credit' },
            { companyName: 'India gold', image: imgPath + '/fin-tech.svg', route: '/india-gold' }
        ]
    },
    {
        label: 'Healthcare', route: '', image: imgPath + '/healtcare.svg',
        caseStudyId: 3,
        submenu: [
            { companyName: 'One More Light', image: imgPath + '/healtcare.svg', route: '/one-more-light' },
            { companyName: 'Apollo 24/7', image: imgPath + '/healtcare.svg', route: '/apollo' }
            // { companyName: 'Somnoware', image: imgPath + '/healtcare.svg', route: '/somnoware' }
        ]
    },
    {
        label: 'E-commerce', route: '', image: imgPath + '/ecommerce.svg',
        caseStudyId: 4,
        submenu: [
            { companyName: 'Shopkirana', image: imgPath + '/ecommerce.svg', route: '/shopkirana' }
        ]
    },
    {
        label: 'Ed-Tech', route: '', image: imgPath + '/ed-tech.svg',
        caseStudyId: 5,
        submenu: [
            { companyName: 'Score leap', image: imgPath + '/ed-tech.svg', route: '/score-leap' }
        ]
    },
    {
        label: 'Media', route: '', image: imgPath + '/media.svg',
        caseStudyId: 6,
        submenu: [
            { companyName: 'News 24', image: imgPath + '/media.svg', route: '/news-24' }
        ]
    },
    {
        label: 'IT Services', route: '', image: imgPath + '/it-service.svg',
        caseStudyId: 7,
        submenu: [
            { companyName: 'Pragmatech', image: imgPath + '/it-service.svg', route: '/pragmatech' }
        ]
    },
    {
        label: 'Logistics', route: '', image: imgPath + '/logistics.svg',
        caseStudyId: 8,
        submenu: [
            { companyName: 'Dunzo', image: imgPath + '/logistics.svg', route: '/dunzo' },
            { companyName: 'Swiggy', image: imgPath + '/logistics.svg', route: '/swiggy' },
            { companyName: 'Adani', image: imgPath + '/logistics.svg', route: '/adani' }
        ]
    },
    {
        label: 'Entertainment', route: '', image: imgPath + '/entertainment.svg',
        caseStudyId: 9,
        submenu: [
            { companyName: 'Tamasha', image: imgPath + '/entertainment.svg', route: '/tamasha' }
        ]
    },
    {
        label: 'Venture Capital', route: '', image: imgPath + '/venture-capital.svg',
        caseStudyId: 10,
        submenu: [
            { companyName: 'Sequoia capital', image: imgPath + '/venture-capital.svg', route: '/sequoia-capital' }
        ]
    }
]