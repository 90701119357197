import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ALL_ROUTES } from './core/routes/all-routes';
import { ProjectLayoutComponent } from './core/layouts/project-layout/project-layout.component';
import { NotfoundComponent } from './core/components/notfound/notfound.component';
import { PublicLayoutComponent } from './core/layouts/public-layout/public-layout.component';
import { PUBLIC_ROUTES } from './core/routes/public-routes';
import { ProjectGuard } from './core/guards/project.guard';
import { MarketingLayoutComponent } from './core/layouts/marketing-layout/marketing-layout.component';
import { staticPagesRoutes } from './core/routes/static-pages-routes';
import { marketingRoutes } from './core/routes/marketing-pages-route';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: PUBLIC_ROUTES
  },
  {
    path: '',
    component: ProjectLayoutComponent,
    children: ALL_ROUTES,
    canActivate: [ProjectGuard]
  },
  {
    path: 'ad',
    component: MarketingLayoutComponent,
    loadChildren: () => import('./marketing-pages/marketing-pages.module').then(m => m.MarketingPagesModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path:'ui-elements',
    loadChildren: () => import('./ui-elements/ui-elements.module').then(m => m.UiElementsModule)
  },
  {
    path:'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'blank',
    loadChildren: () => import('./redirection-screen/redirection-screen.module').then(m => m.RedirectionScreenModule)
  },
  {
    path: 'notfound',
    component: NotfoundComponent
  },
  {
    path: 'unverified',
    component: NotfoundComponent
  },
  {
    path: 'client-lead-generate',
    loadChildren: () => import('.././app/client-lead-generate/client-lead-generate.module').then(m => m.ClientLeadGenerateModule)
},
  {
    path: '**',
    redirectTo: 'notfound'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
