import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-marketing-header',
  templateUrl: './marketing-header.component.html',
  styleUrls: ['./marketing-header.component.scss']
})
export class MarketingHeaderComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private _document
  ) { }

  ngOnInit(): void {
  }

  scrollToTop(): void {
    let quoteForm: HTMLElement = this._document.querySelector('.quoteForm');
    quoteForm.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

}
