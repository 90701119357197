import { Routes } from '@angular/router';
import { HomeGuard } from '../guards/home.guard';
//import {  } from "../../";
// based on the module name in data - different footers will be rendered
export const PUBLIC_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('../../home/home.module').then(m => m.HomeModule),
        canActivate: [HomeGuard]
    },
    {
        path: '',
        loadChildren: () => import('../../public-profile/public-profile.module').then(m => m.PublicProfileModule)
    },
    {
        path: '',
        loadChildren: () => import('../../static-pages/static-pages.module').then(m => m.StaticPagesModule)
    },
    // {
    //     path: '',
    //     loadChildren: () => import('../../case-study/case-study.module').then(m => m.CaseStudyModule)
    // }
];
