import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import {io} from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebservicesService {

  /**notification  */
  private _notificationDataSource = new BehaviorSubject<any>({});
  public notificationData = this._notificationDataSource.asObservable();

  /** chat  */
  private _chatDataSource = new BehaviorSubject<any>([]);
  public chatData = this._chatDataSource.asObservable();
  /** quotation Inbox */

  private _quotationDataArray = new BehaviorSubject<any>([]);
  public quotationData = this._quotationDataArray.asObservable();

  private _quotationStatus = new BehaviorSubject<any>({});
  public quotationStatus = this._quotationStatus.asObservable();

  /** inbox sidebar */
  private _chatListUpdate = new BehaviorSubject<any>([]);
  public chatSideListData = this._chatListUpdate.asObservable();

  constructor(private _baseService: BaseService) { }
  private socket;

  connect(){
    let tokeServer = localStorage.getItem("client_token").replace("Bearer ", "");
    this.socket = io(this._baseService.ws_url+'?token='+ tokeServer);
    let data = {
      token: localStorage.getItem('client_token')
    }
    this.socket.emit('checkNotification', JSON.stringify(data));
    this.socket.on('notificationUpdate', (data) => {
      this._notificationDataSource.next(data);
    });
    // for fixed price project chat notification
    this.socket.on('checkChatNotify', () => {
      this.socket.emit('checkNotification', JSON.stringify({
        token: localStorage.getItem('client_token')
      }));
    });
    // for benchpool leads chat notification
    this.socket.on('checkChatNotify_bp', () => {
      this.socket.emit('checkNotification_bp', JSON.stringify({
        token: localStorage.getItem('client_token')
      }));
    })
    // recieve message for fixed price chat
    this.socket.on('sendMessage', (data) =>{
      this._chatDataSource.next(data);
    });
    // recieve message for benchpool chat
    this.socket.on('sendMessage_bp', (data) =>{
      this._chatDataSource.next(data);
    });
    // recieve updated side bar chat list for fixed price chat
    this.socket.on('updateSideBarChatList', (data) =>{
      this._chatListUpdate.next(data);
    });
    // recieve updated side bar chat list for benchpool chat
    this.socket.on('updateSideBarChatList_bp', (data) =>{
      this._chatListUpdate.next(data);
    });

    // this.socket.on('quotationUpdateInbox', (data) =>{
    //   this._quotationDataArray.next(data);
    // });
    // this.socket.on('quotationStatusUpdate', (data) =>{
    //   this._quotationStatus.next(data);
    // });
  }

  connectChatRoom(id, type?){
    if(type == 'benchpool'){
      this.socket.emit("connectChatRoom_bp", id);
    }else{
      this.socket.emit("connectChatRoom", id);
    }
  }

  leaveChatRoom(id, type){
    this._chatDataSource.next(null);
    if(type == 'benchpool'){
      this.socket.emit("leave_room_bp", id);
    }else{
      this.socket.emit("leave_room", id);
    }
  }

  updateSideChat(status, action, type, chatType){
    let data = {token: localStorage.getItem('client_token'), markRead: status, action: action, type:type};
    if(chatType == 'benchpool'){
      this.socket.emit("getClientChatList_bp", JSON.stringify(data));
    }else{
      this.socket.emit("getClientChatList", JSON.stringify(data));
    }
  }

  leaveChatPage(id, type){
    this._chatListUpdate.next(null);
    if(type == 'benchpool'){
      this.socket.emit("leave_room_bp", id);
    }else{
      this.socket.emit("leave_room", id);
    }
  }

  disconnect() {
    this.socket.close();
    this.socket.disconnect();
  }
}
