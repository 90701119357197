import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  changeButtonStatus = new BehaviorSubject<any>(false);

  baseUrl: string;
  hubspotUrl = environment.HUBSPOT_URL + environment.HUBSPOT_PROFILE_ID + '/' + environment.HUBSPOT_FORM_ID

  constructor(
    private _http: HttpClient,
    private _baseService: BaseService,
    private _errorHandler: ErrorHandlerService
  ) {
    this.baseUrl = this._baseService.baseUrl;
  }

  getPage(callingroute, params) {
    if (callingroute == 'all-services') {
      return this.fetchAllServiceListing();
    }
  }

  // Get service mega menu 
  fetchMegaMenuFooter() {
    return this._http.get(this.baseUrl + "fetchSeviceBasedFooterData").pipe(timeout(75000), catchError((error) => {
      this._errorHandler.routeAccordingToError(error);
      throw error;
    }));
  }

  fetchMegaMenuBasedOnService() {
    return this._http.get(this.baseUrl + "fetchSeviceBasedData").pipe(timeout(75000), catchError((error) => {
      this._errorHandler.routeAccordingToError(error);
      throw error;
    }));
  }

  postDeveloperRequirement(data) {
    return this._http.post(this.baseUrl + 'post_my_lead_request', data).pipe(timeout(75000), catchError((error) => {
      this._errorHandler.routeAccordingToError(error);
      throw error;
    }));
  }

  postDeveloperRequirementForAds(data) {
    return this._http.post(this.baseUrl + 'G-adsLeadRequest', data).pipe(timeout(75000), catchError((error) => {
      this._errorHandler.routeAccordingToError(error);
      throw error;
    }));
  }

  postRequirementDataToHubspot(data) {
    return this._http.post(this.hubspotUrl, data, {
      headers: { 'Access-Control-Allow-Origin': '*' }
    }).pipe(timeout(75000), catchError((error) => {
      console.log(error);

      throw error;
    }));
  }

  // postPythonRequirement(data){
  //   return this._http.post(this.baseUrl + 'hirePythanDev', data).pipe(timeout(75000), catchError((error) => {
  //     this._errorHandler.routeAccordingToError(error);
  //     throw error;
  //   }));
  // }

  // postReactRequirement(data){
  //   return this._http.post(this.baseUrl + 'hireReactJsDev', data).pipe(timeout(75000), catchError((error) => {
  //     this._errorHandler.routeAccordingToError(error);
  //     throw error;
  //   }));
  // }

  // Fetch al service listing 
  fetchAllServiceListing() {
    return this._http.get(`${this.baseUrl}allITServicesPublic`).pipe(timeout(75000), catchError((error) => {
      this._errorHandler.routeAccordingToError(error);
      throw error;
    }));
  }

}
