import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {


  constructor(
    private _router: Router
  ) { }

  ngOnInit() { }

  routeTo(): void{
    if(localStorage.getItem('client_token')){
      this._router.navigate(['/dashboard']);
    }else{
      this._router.navigate(['/']);
    }
  }
}
