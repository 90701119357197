export const environment = {
  production: false,
  baseUrl: 'https://test.supersourcing.com/api/v2/',
  ws_url: 'https://test.supersourcing.com/',
  hostUrl: 'https://test.supersourcing.com/staticBase/',
  frontendUrl: 'https://test.supersourcing.com/',
  individual_developer: 'https://staging.developers.supersourcing.com/',
  GOOGLE_API_KEY: 'AIzaSyAHQWieFrbZoR-IwdISKt0jJLdmQd0HCOM',
  IP_REGISTRY_KEY: 'o3twq3zjhuq4stvs',
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/',
  HUBSPOT_PROFILE_ID: '21484450',
  HUBSPOT_FORM_ID: '2eb2cd13-2cc2-4fe2-961f-fa5753780d8e',
  firebase: {
    apiKey: "AIzaSyCJ-x0XTrY0385Q6SML7tKsFISj76eEcjo",
    authDomain: "bluerang.firebaseapp.com",
    databaseURL: "https://bluerang.firebaseio.com",
    projectId: "bluerang",
    storageBucket: "bluerang.appspot.com",
    messagingSenderId: "12125803069",
    appId: "1:12125803069:web:916dfd464681ab00521054",
    measurementId: "G-0XXHW79CCW"
  }
};

  // export const environment = {
  //   production: false,
  //   baseUrl: 'https://test.supersourcing.com/api/v1/',
  //   ws_url: 'https://test.supersourcing.com/',
  //   hostUrl: 'https://test.supersourcing.com/staticBase/',
  //   frontendUrl: 'https://test.supersourcing.com/',
  //   GOOGLE_API_KEY: 'AIzaSyCcBRZZSgrQHG4Y5S7CbNcJ26IfYUq63DM',
  //   firebase:{
  //     apiKey: "AIzaSyCJ-x0XTrY0385Q6SML7tKsFISj76eEcjo",
  //     authDomain: "bluerang.firebaseapp.com",
  //     databaseURL: "https://bluerang.firebaseio.com",
  //     projectId: "bluerang",
  //     storageBucket: "bluerang.appspot.com",
  //     messagingSenderId: "12125803069",
  //     appId: "1:12125803069:web:916dfd464681ab00521054",
  //     measurementId: "G-0XXHW79CCW"
  //   }
  // };