import { Component, OnInit } from '@angular/core';
import { Store } from '../../services/models/store.model';
import { StoreService } from '../../services/store.service';
import { UtilityService } from '../../utility/utility.service';

@Component({
  selector: 'app-project-layout',
  templateUrl: './project-layout.component.html',
  styleUrls: ['./project-layout.component.scss']
})
export class ProjectLayoutComponent implements OnInit {

  userInfo;

  constructor(
    private _utility: UtilityService,
    private _storeService: StoreService
  ) { }

  ngOnInit() {
    let currentStoreState: Store = this._storeService.getCurrentState();
    // If userdata is avalable 
    if (localStorage.getItem('client_userData')) {
      const user = localStorage.getItem('client_userData');
      const parseJson = this._utility.base64Decode(user)
      this.userInfo = JSON.parse(parseJson);
      // console.log('this.userInfo', this.userInfo)
      currentStoreState.userData = this.userInfo;
    }
    // update store
    this._storeService.updateCompleteStore(currentStoreState);
    this._utility.showCrispChat();
  }

  ngOnDestroy(): void {
    this._utility.hideCrispChat();
  }
}
