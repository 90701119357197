<main class="tt-norms-font">
    <footer class="footer small">
        <div class="container-normal">

            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <a class="footer-logo" [routerLink]="['/']"><img src="assets/imgs/logo.svg" alt="Supersourcing"></a>
                    <p class="f-text mt-4">If you’re a funded Startup or a Company looking to build or grow your Product/Service, Supersourcing helps you hire Engineers on a monthly contract.</p>
                </div>
                
            </div>

            <div class="row mt-5">
                <div class="col-md-12 d-flex mt-5 flex-wrap">
                    <div class="copyright">
                        <p>© {{currentYear}} Supersourcing, Inc. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</main>