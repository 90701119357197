<main class="tt-norms-font">
    <footer class="footer small">
        <div class="container-normal">

            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <a class="footer-logo" [routerLink]="['/']"><img src="assets/imgs/logo.svg" alt="Supersourcing"></a>
                    <p class="f-text mt-4">If you’re a funded Startup or a Company looking to build or grow your Product/Service, Supersourcing helps you hire Engineers on a monthly contract.</p>
                    <p class="f-text mt-4"> 
                        <strong>USA &nbsp; : </strong> 
                        <a routerLink="" href="tel:+1(213)955-7111" class="f-text ms-2">+1 (213) 955-7111, </a>
                        
                        <a routerLink="" href="tel:+1(332)239-4959" class="f-text ms-2">+1 (332) 239-4959</a>
                    </p>
                    <p class="f-text">
                        <strong>UK &nbsp; &nbsp; &nbsp;:</strong> <a routerLink="" href="tel:+447360265345" class="f-text ms-2"> +44 736 026 5345</a>
                    </p>
                    <p class="f-text mt-5 fw-bold">Follow Us</p>
                    <ul class="social-listing no-list-style mt-4">
                        <li>
                            <a href="https://twitter.com/supersrcing" target="_blank" rel="noreferrer"><img src="assets/imgs/icon-twitter.svg" alt="twitter"></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/26617736" target="_blank" rel="noreferrer"><img src="assets/imgs/icon-linkedin.svg" alt="linkedin"></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/Supersourcing/" target="_blank" rel="noreferrer"><img src="assets/imgs/icon-instagram.svg" alt="instagram"></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/supersrcing" target="_blank" rel="noreferrer"><img src="assets/imgs/icon-facebook.svg" alt="Facebook"></a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="row">
                        <div class="col-md-4 col-sm-6 col-6">
                            <div class="f-links">
                                <h4>Product</h4>
                                <ul class="menu-listing">
                                    <li><a routerLink="/about-us">About us</a></li>
                                    <li><a routerLink="/career">Careers</a></li>
                                    <li><a [href]="blogUrl" target="_blank">Blogs</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-6">
                            <div class="f-links">
                                <h4>Services</h4>
                                <ul class="menu-listing">
                                    <li *ngFor="let service of serviceArray;" (mouseover)="hoverService(service?.service_id)">
                                        <a [routerLink]="['/search', service?.service_slug_name]">{{service?.service_name}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-6">
                            <div class="f-links">
                                <h4>Technologies</h4>
                                <div *ngFor="let service of serviceArray;">
                                    <ul class="menu-listing d-none" [ngClass]="{'d-none' : serviceId !== service?.service_id}">
                                        <li *ngFor="let skill of service?.skills;"><a [routerLink]="['/search' + '/' + service?.service_slug_name + '/' + skill?.skills_slug_name]" routerLinkActive="active">{{skill?.skills}}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row mt-5">
                <div class="col-md-12 d-flex mt-5 flex-wrap">
                    <div class="copyright">
                        <p>© {{currentYear}} Supersourcing, Inc. All rights reserved.</p>
                    </div>
                    <div class="f-links ms-auto mb-0">
                        <ul class="menu-listing">
                            <li class="mt-0"><a routerLink="/privacy-policy">Privacy policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</main>