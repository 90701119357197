//Moengage Events

// const win = (window as any)

export class MoengageEventService {
    //Flow no 1 for client Engagement

    addUsers(data, type) {
        // console.log(data, '##moengage'),
        data.first_name && (window as any).Moengage.add_first_name(data.first_name);
        data.last_name && (window as any).Moengage.add_last_name(data.last_name);
        data.email_id && (window as any).Moengage.add_email(data.email_id);
        data.mobile_number && (window as any).Moengage.add_mobile(type == 'google' ? null : data.mobile_country_code + data.mobile_number);
        (window as any).Moengage.add_user_attribute("user_type", "client");
        data.email_id && (window as any).Moengage.add_unique_user_id(data.email_id)
    }
    logoutUser() {
        (window as any).Moengage.destroy_session();
    }
    clientSignup(data, type) {
        (window as any).Moengage.track_event('ClientSignup', { email_id: data.email_id, mobile_number: type == 'google' ? null : data.mobile_country_code + data.mobile_number, user_type: 'client', signup_datetime: data.signup_datetime });
    }

    clientLogin(data, type) {
        (window as any).Moengage.track_event('ClientLogin', { email_id: data.email_id, mobile_number: type == 'google' ? null : data.mobile_country_code + data.mobile_number, user_type: 'client', last_login_datetime: data.last_login_datetime });
    }
    projectPostStart(type) {
        (window as any).Moengage.track_event('ProjectPostStart', { project_type: type });
    }

    projectPostStep_1Back(data) {

        (window as any).Moengage.track_event('ProjectPostStep_1Back', data);
    }
    projectPostStep_1Complete(data) {

        (window as any).Moengage.track_event('ProjectPostStep_1Complete', data);
    }
    projectPostStep_2Back(data) {
        (window as any).Moengage.track_event('ProjectPostStep_2Back', data);
    }
    projectPostStep_2Complete(data) {
        (window as any).Moengage.track_event('projectPostStep_2Complete', data);
    }
    projectPostEnd(data) {
        (window as any).Moengage.track_event('ProjectPostEnd', data);
    }


}