import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private _utility: UtilityService,
    private _router: Router
  ){}

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot)
  : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(!this._authService.verifyUserLogin()){
      let url = this._utility.base64Encode(state?.url);
      this._router.navigate(['/auth/login', {redirectTo: url}]);
      return false;
    }else{
      return true;
    }
  }
}
