import { Routes } from '@angular/router';
// based on the module name in data - different footers will be rendered
export const ALL_ROUTES: Routes = [
    {
        path:'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'my-profile',
        loadChildren: () => import('../../profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'setting',
        loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule)
    },
    {
        path: 'recommendations',
        loadChildren: () => import('../../recommendation/recommendation.module').then(m => m.RecommendationModule)
    },
    {
        path: 'project-details/:id',
        loadChildren: () => import('../../project-details/project-details.module').then(m => m.ProjectDetailsModule)
    },
    {
        path: 'inbox',
        loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
    },
    {
        path: 'notification',
        loadChildren: () => import('../../notification/notification.module').then(m => m.NotificationModule)
    },
    {
        path:'matched-resources',
        loadChildren: () => import('../../matched-resources/matched-resources.module').then(m => m.MatchedResourcesModule)
    },
    {
        path:'project-onboarding',
        loadChildren: () => import('../../project-onboarding/project-onboarding.module').then(m => m.ProjectOnboardingModule)
    },
    {
        path:'hiring-developers',
        loadChildren: () => import('../../hiring-developers/hiring-developers.module').then(m => m.HiringDevelopersModule)
    },
    {
        path: 'testimonial',
        loadChildren: () => import('../../testimonial/testimonial.module').then(m => m.TestimonialModule)
    },
    // {
    //     path: 'client-lead-generate',
    //     loadChildren: () => import('../../client-lead-generate/client-lead-generate.module').then(m => m.ClientLeadGenerateModule)
    // },
];
