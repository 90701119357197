import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CheckboxModule} from 'primeng/checkbox';
import { CalendarModule } from "primeng/calendar";
import {AutoCompleteModule} from 'primeng/autocomplete';
import {RatingModule} from 'primeng/rating';
import { PaginatorModule } from "primeng/paginator";
import {InputSwitchModule} from 'primeng/inputswitch';
import {ProgressBarModule} from 'primeng/progressbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {AccordionModule} from 'primeng/accordion';
import {TableModule} from 'primeng/table';
@NgModule({
    imports: [
      CommonModule,
      DropdownModule,
      MultiSelectModule,
      RadioButtonModule,
      CheckboxModule,
      CalendarModule,
      AutoCompleteModule,
      RatingModule,
      PaginatorModule,
      InputSwitchModule,
      ProgressBarModule,
      OverlayPanelModule,
      AccordionModule,
      TableModule
    ],
    exports: [
      DropdownModule,
      MultiSelectModule,
      RadioButtonModule,
      CheckboxModule,
      CalendarModule,
      AutoCompleteModule,
      RatingModule,
      PaginatorModule,
      InputSwitchModule,
      ProgressBarModule,
      OverlayPanelModule,
      AccordionModule,
      TableModule
    ],
    declarations: []
  })

  export class PrimeNgModule { }