<header class="header static-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-md-6 col-5">
                <a routerLink="/" class="logo"><img src="assets/imgs/logo-dark.svg" alt="Supersourcing"></a>
            </div>
            <div class="col-lg-8 navbar-menu main-menu dropdown-menu-main" [class.open]="classToggled">
                <ul class="d-flex flex-wrap justify-content-center main-ul align-items-center">
                    <li class="close-left-menu" (click)="toggleField()">&#10006;</li>
                    <li class="dropdown position-relative">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">Hire Developers</a>
                        <div class="for-dropdown dropdown-menu seo-dropdown block-scroller"
                            aria-labelledby="navbarDropdown">
                            <ul>
                                <li *ngFor="let item of staticPageRoutes">
                                    <a [routerLink]="item?.route">{{item?.label}}</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="dropdown" dropdown>
                        <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">Hire Agency</a>
                        <div class="mega-menu dropdown-menu" aria-labelledby="navbarDropdown">
                            <div class="mega-menu-left">
                                <ul class="menu-listing">
                                    <li>
                                        <h4>Services</h4>
                                    </li>
                                    <li *ngFor="let service of serviceMenuList;"
                                        (mouseover)="hoverService(service?.service_id)" class="active">
                                        <a [routerLink]="['/search', service?.service_slug_name]"
                                            class="full-width-100">
                                            <img [src]="'assets/imgs/nav-icons/'+ service?.service_slug_name +'.svg'"
                                                alt="Services"> {{service?.service_name}}
                                        </a>
                                    </li>
                                    <li class="all-services-li">
                                        <a routerLink="/all-services">
                                            All Services <img src="assets/imgs/right-arrow-blue.svg" alt="Arrow"
                                                class="icon-right-img float-end">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <ng-container *ngFor="let service of serviceMenuList;">
                                <!-- *ngIf="serviceId === service?.service_id" -->
                                <div class="mega-menu-right flex-wrap fade-in"
                                    [ngClass]="{'d-flex': serviceId === service?.service_id}">
                                    <ul class="menu-listing">
                                        <li>
                                            <h4>Technologies</h4>
                                        </li>
                                        <li *ngFor="let skill of service?.skills;">
                                            <a [routerLink]="['/search' + '/' + service?.service_slug_name + '/' + skill?.skills_slug_name]"
                                                routerLinkActive="active">{{skill?.skills}}</a>
                                        </li>
                                    </ul>
                                    <ul *ngIf="service?.seoPagesDomain.length" class="menu-listing">
                                        <li>
                                            <h4>Industries</h4>
                                        </li>
                                        <li *ngFor="let industry of service?.seoPagesDomain;">
                                            <a [routerLink]="['/search' + '/' + service?.service_slug_name + '/industry/' + industry?.domain_slug_name]"
                                                routerLinkActive="active">{{industry?.domain}}</a>
                                        </li>
                                    </ul>
                                    <ul *ngIf="this.serviceId == 1" class="menu-listing">
                                        <li>
                                            <h4>Location</h4>
                                        </li>
                                        <li><a [routerLink]="['/web-designing-company-in-chennai']"
                                                routerLinkActive="active">Chennai</a></li>
                                    </ul>
                                </div>
                            </ng-container>

                            
                        </div>
                    </li>

                    <!-- Case study-->
                    <!-- <li class="dropdown" dropdown>
                        <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">Case Study</a>
                        <div class="mega-menu dropdown-menu" aria-labelledby="navbarDropdown">
                            <div class="mega-menu-left" [ngClass]="{'d-flex': caseStudyId === caseStudyPagesRoutes[0].submenu}">
                                <ul class="menu-listing">
                                    <li>
                                        <h4>Case Study</h4>
                                    </li>

                                    <li *ngFor="let service of caseStudyPagesRoutes;" (mouseover)="hoverCaseStudy(service?.caseStudyId, service?.label)" class="active">
                                        <a class="full-width-100">
                                            <img [src]="service?.image" alt="Services"> {{service?.label}}
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                            <ng-container *ngFor="let service of caseStudyPagesRoutes;">
                                <div class="mega-menu-right flex-wrap fade-in"
                                [ngClass]="{'d-flex': caseStudyId === service?.caseStudyId}" >
                                    <ul class="menu-listing">
                                        <li>
                                            <h4>{{caseStudyLabel}}</h4>
                                        </li>
                                        <li *ngFor="let companyData of service?.submenu;">
                                            <a routerLinkActive="active" [routerLink]="companyData?.route" (click)="navigateCaseStudy(companyData?.route)">{{companyData?.companyName}}</a>
                                        </li>
                                    </ul>                                    
                                </div>
                            </ng-container>
                        </div>
                    </li> -->
                    <!-- Case study-->

                    <li class="dropdown position-relative">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">For Talent Partners</a>
                        <div class="for-dropdown dropdown-menu" aria-labelledby="navbarDropdown">
                            <h5>Verified Leads In</h5>
                            <ul>
                                <li>
                                    <a routerLink="/benchpool-projects-agency">
                                        <h6>BenchPool Projects</h6>
                                        <p>Loan out your developers and get paid monthly. Avoid delivery headaches & get
                                            a constant MRR. </p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="dropdown position-relative">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">Company</a>
                        <div class="company-dropdown dropdown-menu" aria-labelledby="navbarDropdown">
                            <ul>
                                <li>
                                    <a [routerLink]="['/about-us']"><img src="assets/imgs/about.svg" alt="About US">
                                        About Us</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/career']"><img src="assets/imgs/career.svg" alt="Career">
                                        Career</a>
                                </li>
                                <li>
                                    <a [href]="blogUrl" target="_blank"><img src="assets/imgs/blog.svg" alt="Blogs">
                                        Blogs</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li><a [routerLink]="['/before-after']">Reviews</a></li>
                    <li *ngIf="changeBtn != true"><a routerLink="/developer" class="Apply-for-btn" (click)="toggleField()">Apply For Jobs</a></li>
                    <li *ngIf="changeBtn == true"><a [href]="individualDeveloperSignupUrl" class="Apply-for-btn">Apply Now</a></li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-6 col-7">
                <div class="navbar-menu dropdown-menu-main d-flex align-items-center justify-content-end">
                    <ul *ngIf="!(isAnyUserLoggedIn && currentRoute.includes('/profile/'))"
                        class="d-flex flex-wrap main-ul align-items-center">
                        <li class="dropdown login-signup-dropdown position-relative">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown"
                                role="button" data-bs-toggle="dropdown" aria-expanded="false">Login</a>
                            <div class="for-dropdown dropdown-menu" aria-labelledby="navbarDropdown">
                                <ul>
                                    <li>
                                        <a (click)="customLoginAsClientEvent()" routerLink="/auth/login" class="d-flex">
                                            <figure><img src="assets/imgs/user.svg" width="24" alt="Client"></figure>
                                            <div class="text ps-3">
                                                <h6 class="fs-sm">Login as Client</h6>
                                                <p class="fw-normal">Looking to hire the right team for your project?
                                                </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a (click)="customLoginAsTPEvent()" [href]="companyLoginURL" class="d-flex">
                                            <figure><img src="assets/imgs/agency.svg" width="24" alt="Client"></figure>
                                            <div class="text ps-3">
                                                <h6 class="fs-sm">Login as Talent Partner</h6>
                                                <p class="fw-normal">Looking to expand your business and your income?
                                                </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="d-flex" [href]="individualDeveloperLoginUrl">
                                            <figure><img src="assets/imgs/user.svg" width="24" alt="Client"></figure>
                                            <div class="text ps-3">
                                                <h6 class="fs-sm">Login as Developer</h6>
                                                <p class="fw-normal">Looking to apply for jobs with top teams?</p>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="dropdown login-signup-dropdown position-relative">
                            <a class="nav-link dropdown-toggle color-dark-blue" href="javascript:void(0)"
                                id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">Signup</a>
                            <div class="for-dropdown dropdown-menu" aria-labelledby="navbarDropdown">
                                <ul>
                                    <li>
                                        <a (click)="customSignUpAsClientEvent()"
                                            [routerLink]="['/auth/signup', {routeTo: 'post-project'}]" class="d-flex">
                                            <figure><img src="assets/imgs/user.svg" width="24" alt="Client"></figure>
                                            <div class="text ps-3">
                                                <h6 class="fs-sm">Sign up as client</h6>
                                                <p class="fw-normal">Looking to find the right Talent Partner for your
                                                    project?</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a (click)="customSignUpAsTPEvent()" [href]="companySignUpURL" class="d-flex">
                                            <figure><img src="assets/imgs/agency.svg" width="24" alt="Agency"></figure>
                                            <div class="text ps-3">
                                                <h6 class="fs-sm">Sign up as Talent Partner</h6>
                                                <p class="fw-normal">Looking to make more money for your team?</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="d-flex" [href]="individualDeveloperSignupUrl">
                                            <figure><img src="assets/imgs/user.svg" width="24" alt="Client"></figure>
                                            <div class="text ps-3">
                                                <h6 class="fs-sm">Sign up as Developer</h6>
                                                <p class="fw-normal">Looking to apply for jobs with top teams?</p>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <a class="toggle-menu-mobile ms-4" id="sidebarToggle" (click)="toggleField()">
                        <img src="assets/imgs/menu.svg" width="24" alt="Menu">
                    </a>
                </div>
            </div>
        </div>
    </div>
</header>