import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  baseUrl = environment.baseUrl;
  ws_url = environment.ws_url;
  hostUrl = environment.hostUrl;
constructor() { }
}
