import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from './core/services/store.service';
import { UtilityService } from './core/utility/utility.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { WebservicesService } from './core/services/webservices.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MessagingService } from './core/services/messaging.service';
import { Meta } from '@angular/platform-browser';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';
import { VisitorsService } from './core/utility/visitors-location.service';
import * as AOS from 'aos';
declare var gtag

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'supersourcing-client';
  userData;
  companyDetails;
  cartData;
  private _unsubscribe$ = new Subject<boolean>();

  // utm pram
  utmParam: any
  constructor(
    private _storeService: StoreService,
    private _utility: UtilityService,
    private _authService: AuthService,
    private wsService: WebservicesService,
    private _router: Router,
    private _messaging: MessagingService,
    private _metaService: Meta,
    private gtmService: GoogleTagManagerService,
    private _activetedRoute: ActivatedRoute,
    private _cookieService: CookieService,
    private _visitorService: VisitorsService
  ) {
    if (this._authService.verifyUserLogin()) {
      this._messaging.requestPermission();
    }
    this._authService.checkIfAnyUserLoggedIn();
  }

  ngOnInit() {
    AOS.init();
    this.pushRouteToGTM();
    // Meta tags 
    this._metaService.addTags([
      { charset: 'UTF-8' },
      { name: 'description', content: 'Whether you are looking for a dedicated mobile app developer, a top web design agency or an offshore software development company, this is the single place to find the best technology partner for your product.' }
    ]);
    if (this._authService.verifyUserLogin()) {
      this.wsService.connect();
      if (localStorage.getItem('client_userData')) {
        this.userData = JSON.parse(this._utility.base64Decode(localStorage.getItem('client_userData')));
        this._storeService.setState({
          userData: this.userData
        })
      }
      // after 24h user logged out
      this.sessionOut();
    }

    this._activetedRoute.queryParams.subscribe(
      (res) => {
        this.utmParam = res;
        if (this.utmParam && this.utmParam.utm_campaign) {
          this.setCookie(this.utmParam)
        }
      });

    if (!localStorage.getItem('country_code')) {
      this.getIPinfo();
    } else {
      let userData = JSON.parse(this._utility.base64Decode(localStorage.getItem('country_code')))
      this.setCurrencyData(userData);
    }
  }

  getIPinfo() {
    let userData;
    this._visitorService.getIpAddress().pipe(takeUntil(this._unsubscribe$)).subscribe({
      next: (res: any) => {
        userData = {
          country_code: res.location.country.name.replace(/\s+/g, '')
        };
        this.setCurrencyData(userData);
        localStorage.setItem('country_code', this._utility.base64Encode(JSON.stringify(userData)));
      }, error: (error) => {
        // console.log(error);
        userData = {
          currency: 'INR',
          address: 'India',
          country_code: 'India'
        };
        this.setCurrencyData(userData);
        localStorage.setItem('currency', this._utility.base64Encode(JSON.stringify(userData)));
        this._utility.routingAccordingToError(error);
      }
    });
  }

  setCurrencyData(userData): void {
    this._storeService.setState({
      userCountryCode: userData.country_code
    });
  }

  pushRouteToGTM(): void {
    // push GTM data layer for every visited page
    this._router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }
  // set cookie
  setCookie(param) {
    param = this._utility.base64Encode(JSON.stringify(param))
    // param = JSON.stringify(param)
    this._cookieService.set('utmParam', param, 7, '/', '', false, "Strict");
  }
  // session out function
  sessionOut() {
    const hours = 2;
    const now: any = new Date().getTime();
    const setupTime: any = localStorage.getItem('client_setupTime');
    if (now - setupTime > hours * 60 * 60 * 1000) {
      this._authService.deleteToken().pipe(takeUntil(this._unsubscribe$)).subscribe({
        next: (success: any) => {
          this._authService.userLogout();
          this._router.navigate(['auth/login']);
        },
        error: (error) => {
          // console.log(error);
          this._utility.routingAccordingToError(error);
        }
      });
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }
}
